@tailwind base;
@tailwind components;
@tailwind utilities;

@import './_variables.css';
@import './_layout.css';
@import './_typography.css';
@import './_loading-spinner.css';
@import './_navigation.css';
@import './_mobile-navigation.css';
@import './_hamburger.css';
@import './_global.css';

@import './_cards.css';
@import './_fslightbox_overrides.css';
