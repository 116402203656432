.loading-spinner {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 5px solid #ccc;
  border-radius: 50%;
  border-top-color: var(--color-orange);
  animation: loading-spinner 1s ease-in-out infinite;
  -webkit-animation: loading-spinner 1s ease-in-out infinite;
}

@keyframes loading-spinner {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes loading-spinner {
  to {
    -webkit-transform: rotate(360deg);
  }
}
