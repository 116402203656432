.mobile-navigation ul {
}

.mobile-navigation li {
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #cccccc;
}

.mobile-navigation li:last-child {
  border: none;
}
