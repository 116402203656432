.icon-hamburger {
  width: 28px;
  height: 28px;
  cursor: pointer;
  transition-duration: 0.5s;
  position: relative;
}

.icon-hamburger span {
  transition-duration: 0.5s;
  position: absolute;
  left: 0;
  top: 21px;
  width: 26px;
  height: 3px;
  border-radius: 2px;
  background-color: white;
}

.icon-hamburger span::before {
  transition-duration: 0.5s;
  position: absolute;
  left: 0;
  top: -7px;
  width: 26px;
  height: 3px;
  border-radius: 2px;
  background-color: white;
  content: '';
}

.icon-hamburger span::after {
  transition-duration: 0.5s;
  position: absolute;
  left: 0;
  top: 7px;
  width: 26px;
  height: 3px;
  border-radius: 2px;
  background-color: white;
  content: '';
}

.icon-hamburger.active span {
  width: 28px;
  transition-duration: 0.5s;
  background: transparent;
}

.icon-hamburger.active span:before {
  top: 0;
  width: 28px;
  transform: rotate(45deg);
  background: var(--color-blue);
}

.icon-hamburger.active span:after {
  top: 0;
  width: 28px;
  transform: rotate(-45deg);
  background: var(--color-blue);
}
