.mobile-menu {
}

.mobile-menu ul {
}

.mobile-menu li {
  margin-left: 5rem;
}

.mobile-menu li a:link,
.mobile-menu li a:visited {
}

.mobile-menu li a:hover,
.mobile-menu li a:active {
}
