html,
body {
  letter-spacing: 0.03rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  background-color: var(--color-offwhite);
  color: #082f49;
}

.text-justify {
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  letter-spacing: 0;
}
