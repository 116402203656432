.card {
  border-radius: 0.5rem;
  min-height: 500px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.card__hover {
  background: rgba(12, 74, 110, 0.7);
  height: 100%;
  opacity: 0;
  position: absolute;
  transition: opacity 0.3s ease;
  width: 100%;
}

.card__content {
  background: linear-gradient(-180deg, rgba(12, 74, 110, 0) 33%, rgb(12, 74, 110) 100%);
  cursor: pointer;
  display: flex;
  height: 100%;
  left: 0;
  object-fit: cover;
  padding: 20px;
  position: absolute;
  top: 0;
  width: 100%;
}

.card__body {
  height: 100%;
  transform: translateY(100%);
  transition: transform 0.3s ease;
  width: 100%;
}

.card__title {
  transform: translateY(-100%);
  transition: transform 0.3s ease;
}

.card__text {
  opacity: 0;
  transition: opacity 0.7s;
}

.card:hover .card__hover {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.card:hover .card__body {
  transform: translateY(0);
}

.card:hover .card__title {
  transform: translateY(0);
}

.card:hover .card__text {
  opacity: 1;
}
